

























































































































































































































































































































































import { Component, Inject, Ref, Vue } from 'vue-property-decorator';
import { config } from '@/config';
import { SubscriptionManager } from '@/core/utils';
import { ImageAnalyzer } from '@/services/image-analyzer';
import { PlayerController, PlayerState } from '@/services/player-controller';

@Component({})
export default class Breadboard extends Vue implements VueComponentLifecycle {
  state: PlayerState = {};
  currentTime = 0;
  remainingAdTime = 0;

  config = config;
  playlistUrl = '/test/playlist-accuweather.json';
  seekTime = 0;
  seekTimePercent = 0;
  skipBackwardTime = 10;
  skipForwardTime = 30;
  ratePercent = 100;
  volumePercent = 100;
  primaryThemeColor = config.theme.defaultColor;

  @Inject() private controller!: PlayerController;
  @Inject() private imageAnalyzer!: ImageAnalyzer;
  @Ref() private thumbnailImg!: HTMLImageElement;
  private subscriptions!: SubscriptionManager;

  get showPrevious(): boolean {
    return !!(this.state.hasPrevious && this.currentTime < 30);
  }

  get trackTitle(): string {
    return this.state.story ? this.state.story.content.title : '';
  }

  get trackSubtitle(): string {
    return this.state.story && this.state.story.content.author
      ? `by ${this.state.story.content.author}`
      : '';
  }

  get thumbnailSrc(): string {
    return this.state.publisher
      ? this.state.publisher.display.thumbnail.small ||
          this.state.publisher.display.thumbnail.default
      : '';
  }

  /* lifecycle */

  created() {
    this.subscriptions = new SubscriptionManager();
    this.state = this.controller.state;
  }

  mounted() {
    this.subscriptions.watch<Partial<PlayerState>>(
      this.controller.updates$,
      update => {
        this.state = { ...this.state, ...update };
      }
    );
    this.subscriptions.watch<number>(
      this.controller.currentTime$,
      currentTime => {
        this.currentTime = currentTime;
      }
    );
    this.subscriptions.watch<number>(
      this.controller.remainingAdTime$,
      remainingTime => {
        this.remainingAdTime = remainingTime;
      }
    );
    this.subscriptions.watch<string>(
      this.imageAnalyzer.observeDominantColor(this.thumbnailImg),
      color => {
        this.primaryThemeColor = color;
      }
    );
  }

  destroyed(): void {
    this.subscriptions.close();
  }
}
